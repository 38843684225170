import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Dealers.scss';

const AWS_API = process.env.REACT_APP_AWS_API;
const styles = {
  width: '50px'
};

const Dealers = () => {
  const [leftDealers, setLeftDealers] = useState([]);
  const [middleDealers, setMiddleDealers] = useState([]);
  const [rightDealers, setRightDealers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API}/dealers`);
        await response.json().then((res) => {
          let splitter = res.length / 3;
          setLeftDealers(res.slice(0, splitter));
          setMiddleDealers(res.slice(splitter, splitter * 2));
          setRightDealers(res.slice(splitter * 2));
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="dealers_wrapper">
      <p className="dealers_title">Participating Dealers</p>
      <div className="line__separator"></div>
      <div className="columns_wrapper">
        <div className="column">
          {leftDealers.map(({ id, sub_dealers, link }) =>
            link ? (
              <div className="single_dealer" key={id}>
                {sub_dealers.map((subdealer, idx) => (
                  <p className="dealer" key={idx}>
                    {subdealer}
                  </p>
                ))}
                <Link to={link}>
                  {' '}
                  <p className="contact_name_blue">{id}</p>
                </Link>
              </div>
            ) : (
              <div className="single_dealer" key={id}>
                {sub_dealers.map((subdealer, idx) => (
                  <p className="dealer" key={idx}>
                    {subdealer}
                  </p>
                ))}

                <p className="contact_name">{id}</p>
              </div>
            )
          )}
        </div>
        <div className="column_spacer" style={styles}></div>
        <div className="colum">
          {middleDealers.map(({ id, sub_dealers }) => (
            <div className="single_dealer" key={id}>
              {sub_dealers.map((subdealer, idx) => (
                <p className="dealer" key={idx}>
                  {subdealer}
                </p>
              ))}
              <p className="contact_name">{id}</p>
            </div>
          ))}
        </div>
        <div className="column_spacer" style={styles}></div>
        <div className="colum">
          {rightDealers.map(({ id, sub_dealers }) => (
            <div className="single_dealer" key={id}>
              {sub_dealers.map((subdealer, idx) => (
                <p className="dealer" key={idx}>
                  {subdealer}
                </p>
              ))}
              <p className="contact_name">{id}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dealers;
