import React from 'react';

import './Stats.scss';

const AWS_API = process.env.REACT_APP_AWS_API;

const Stats = () => {
  const [stats, setStats] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API}/stats`);
        await response.json().then((res) => setStats(res));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="stats_container">
      {stats
        .sort((a, b) => a.position - b.position)
        .map(({ name, count }, idx) => (
          <div className="stat_box" key={idx}>
            <p className="stat_count">{count}</p>
            <p className="stat_name">{name}</p>
          </div>
        ))}
    </div>
  );
};

export default Stats;
