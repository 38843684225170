import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

/* Regular components */
import MainPage from './components/MainPage';
import Menu from './components/Menu';

// import NewsPage from './pages/NewsPage';
import AllArticles from './components/AllArticles';
import PressPage from './pages/PressPage';
import DonatePage from './pages/DonatePage';
import DashboardServicePage from './pages/DashboardServicePage';
import ThankyouPage from './pages/ThankyouPage';

import Footer from './components/Footer';
import AllLoanSupport from './components/AllLoanSupport';

import SponsorsPage from './pages/SponsorsPage';

/* Article components */
import SanitizeCar from './components/articles/SanitizeCar';
import VirtualCarBuying from './components/articles/VirtualCarBuying';
import HowDealersProtecting from './components/articles/HowDealersProtecting';
import NYTough from './components/articles/NYTough';

/* Dealer Highlight */
import DealerHighlightBeyer from './components/DealerHighlightBeyer';
import DealerHighlightDCHAudi from './components/DealerHighlightDCHAudi';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Menu />
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route
            exact
            path="/how-to-sanitize-your-car"
            component={SanitizeCar}
          />
          <Route
            exact
            path="/virtual-car-buying"
            component={VirtualCarBuying}
          />
          <Route
            exact
            path="/customer-protection"
            component={HowDealersProtecting}
          />
          <Route exact path="/all-articles" component={AllArticles} />
          <Route exact path="/sponsors" component={SponsorsPage} />
          <Route
            exact
            path="/bridget-beyer-morristown"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/morristown-medical-center-and-beyer-automotive';
              return null;
            }}
          />
          <Route
            exact
            path="/sawyer-lyons"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/sawyer-lyons';
              return null;
            }}
          />

          <Route
            exact
            path="/bridget-beyerx-elderly"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/bridget-beyerx-elderly';
              return null;
            }}
          />
          <Route
            exact
            path="/constellation-do-good"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/constellation-do-good';
              return null;
            }}
          />
          <Route
            exact
            path="/letters-to-elderly"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/letters-to-elderly';
              return null;
            }}
          />
          <Route exact path="/ny-tough" component={NYTough} />
          <Route exact path="/support" component={AllLoanSupport} />

          {/* <Route exact path="/news" component={NewsPage} /> */}
          <Route exact path="/press" component={PressPage} />
          <Route
            exact
            path="/articles"
            render={() => <Redirect to="/all-articles" />}
          />

          <Route
            exact
            path="/all-american-auto-group"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/all-american-auto-group-and-front-line-workers';
              return null;
            }}
          />
          <Route
            exact
            path="/sansone-auto"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/sansone-auto-group-and-rescuing-leftover-cuisine';
              return null;
            }}
          />
          <Route
            exact
            path="/baierl-acura"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/baierl-acura-and-north-hills-community-outreach/';
              return null;
            }}
          />
          <Route
            exact
            path="/bay-ridge-shoot"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/bay-ridge-and-rescuing-leftover-cuisine/';
              return null;
            }}
          />
          <Route
            exact
            path="/dch-audi"
            component={() => {
              window.location.href =
                'https://blog.dogoodautocoalition.com/dch-millburn-audi-and-first-responders';
              return null;
            }}
          />
          <Route
            exact
            path="/dealer-highlight-beyer"
            component={DealerHighlightBeyer}
          />
          <Route
            exact
            path="/dealer-highlight-dch-audi"
            component={DealerHighlightDCHAudi}
          />
          <Route exact path="/donate" component={DonatePage} />
          <Route exact path="/thankyou" component={ThankyouPage} />
          <Route
            exact
            path="/dashboard-service"
            component={DashboardServicePage}
          />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
};
export default App;
