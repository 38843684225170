import React from 'react';
import { Link } from 'react-router-dom';

import './Features.scss';
import videoStillFeatures from '../images/features/features_video_still_2.jpg';

const Features = () => {
  return (
    <div className="features_container">
      <p className="features_title">NY Tough</p>
      <div className="line_separator"></div>
      <div className="features_content">
        <div>
          <p className="text">
            {' '}
            {/* How dealers are protecting their customers in the age of COVID-19 */}
            Banding together nationwide to fight against COVID-19, starting
            right here in our home of New York City.
          </p>
          {/* <Link to="/customer-protection"> */}
          <Link to="/ny-tough">
            {' '}
            <p className="read_more">Read more </p>
          </Link>
        </div>
        <div className="separator"></div>
        <video
          className="features_video"
          controls={true}
          playsInline
          poster={videoStillFeatures}
        >
          <source
            type="video/mp4"
            src="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/nytough/new_york_tough.mp4"
          />
        </video>
        {/* <div className="features_video">
          <iframe src="https://player.vimeo.com/video/403493566?color=ffffff&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div> */}
      </div>
    </div>
  );
};

export default Features;
