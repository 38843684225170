import React, { useEffect } from 'react';

import Meta from './../Meta';
import Sources from '../Sources';

import './Article.scss';

const links = [
  'https://blog.nada.org/2020/03/23/auto-dealerships-are-stepping-up-during-covid-19/',
  'https://www.cbtnews.com/how-dealers-can-address-the-effects-of-covid-19/',
  'https://www.consumerreports.org/tires-car-care/how-to-kill-coronavirus-in-your-car-without-damaging-interior-surfaces/',
  'https://www.usatoday.com/story/money/cars/2020/03/24/auto-dealers-coronavirus-covid-19-auto-repair-service/2877553001/'
];

const HowDealersProtecting = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="article_wrapper">
      <Meta
        title="Features > How Dealers Are Protecting Their Customers"
        description="How dealers are protecting their customers in the age of COVID-19"
        image="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/customer-protection/customer_service1.jpg"
      />
      <div className="article_heroimg_customerProtection"></div>
      <div className="article_content">
        <p className="article_main_title">
          How Dealers are Protecting their Customers in the Age of COVID-19
        </p>
        <div className="line__separator"></div>

        <p className="article_paragraph">
          The COVID-19 pandemic is impacting every aspect of American life,
          including the auto industry. Now more than ever, American auto
          dealerships are raising the standards on community health and safety.{' '}
          <br /> <br />
          The tense climate created by the coronavirus has allowed dealerships
          to come together and adapt to these unprecedented times. The National
          Automotive Dealership Association (NADA) has recently reported on
          actions dealerships are taking across the nation to better serve their
          communities. Some of these include:
        </p>
        <p className="article_title">Feature: Open Road Auto Group</p>
        <p className="article_paragraph">
          Open Road Auto Group has acted as a community leader when it comes to
          safety in the age of COVID-19. In the video above, you can see how
          they have extended special offers on vehicle maintenance and
          highlighted efforts to ensure cleanliness and safety.
        </p>
        <video className="article_video" controls>
          <source
            type="video/mp4"
            src="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/customer-protection/_1_VID_200319_OpenRoad_Covid19_MikeMoraes_Webversion.mp4"
          />
        </video>
        <p className="article_subtitle">Delivery & Service</p>
        <p className="article_paragraph">
          Many states are requiring the closure of sales departments around the
          country. To help prevent the spread of COVID-19, showrooms are closing
          and “shelter-in-place” guidelines are being implemented. To adapt to
          the changing needs of their customers, many dealerships are now
          providing dropoff and delivery for servicing. Dealerships are taking
          the necessary precautions to make sure their communities are mobile
          and have access to essential items and services.
        </p>
        <p className="article_subtitle">Online Shopping</p>
        <p className="article_paragraph">
          To help individuals purchase vehicles, many dealerships have built
          online shopping centers. Having access to a dealer’s inventory on the
          web means that you can go through the entire purchase process without
          leaving your own home.
        </p>
        <p className="article_subtitle">Staff Accessibility</p>

        <p className="article_paragraph">
          Dealers are becoming even more communicative, maintaining their
          websites regularly and updating information about their hours of
          operation or new inventory. This proactive communication with the
          public is preventing unnecessary in-person exchanges that could have
          been done remotely.
        </p>
        <img
          className="article_img"
          src="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/customer-protection/customer_protection2.jpg"
          alt=""
        />
        <p className="article_subtitle">
          Communicating Safety Prevention Policy
        </p>
        <p className="article_paragraph">
          Dealerships are communicating to their staff and customers the
          importance of following the proper health procedures. To prevent any
          further spread of the coronavirus, dealers are encouraging the
          following guidelines from the Centers of Disease Control and
          Prevention (CDC):
        </p>
        <ul>
          <li>
            “Wash your hands often with soap and water for at least 20 seconds
            especially after you have been in a public place, or after blowing
            your nose, coughing, or sneezing.”
          </li>
          <li>
            “Avoid touching your eyes, nose, and mouth with unwashed hands.”
          </li>
          <li>
            “Cover your mouth and nose with a tissue when you cough or sneeze or
            use the inside of your elbow.”
          </li>
          <li>“Clean AND disinfect frequently touched surfaces daily.”</li>
        </ul>
      </div>
      <Sources links={links} />
    </div>
  );
};

export default HowDealersProtecting;
