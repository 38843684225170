import React from 'react';
import { Link } from 'react-router-dom';
import DGACLogo from './../img/logo/dgac_logo.png';
import CALogo from '../img/logo/ca_HorizontalMED.png'

import './Menu.scss';

const Menu = () => {
  return (
    <div className="container__menu">
      <div className="logo__dgac">
        <Link to="/">
          <img src={DGACLogo} alt="DGAC Logo" />
        </Link>
      </div>
      <div className="menu__text">
        <a
          href="https://www.constellationagency.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          POWERED BY
          <div className="logo__ca">
            <img src={CALogo} alt="Constellation Agency Logo" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Menu;
