import React, { useEffect } from 'react';

import Meta from './../Meta';
import Sources from '../Sources';

import './Article.scss';

const links = [
  'https://www.autoblog.com/2020/03/12/coronavirus-car-interior-cleaning-how-to/',
  'https://www.kbb.com/articles/car-advice/how-to-clean-your-car-of-the-coronavirus/',
  'https://www.consumerreports.org/tires-car-care/how-to-kill-coronavirus-in-your-car-without-damaging-interior-surfaces/',
  'https://thenewswheel.com/tips-for-sanitizing-your-car-against-covid-19/',
  'https://www.marketwatch.com/story/how-to-clean-your-car-to-reduce-the-risk-of-coronavirus-2020-03-23'
];

const SanitizeCar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="article_wrapper">
      <Meta
        title="Tips > How to properly sanitize your car"
        description="How to properly sanitize your car"
        image="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/sanitize+car/sanitize_car1.jpg"
      />
      <div className="article_heroimg_sanitizecar"></div>
      <div className="article_content">
        <p className="article_main_title">How to properly sanitize your car</p>
        <div className="line__separator"></div>
        <p className="article_subtitle">Car sanitation during COVID-19</p>
        <p className="article_paragraph">
          The recent development of the novel coronavirus poses new questions
          for vehicle owners about how to properly clean their cars, trucks and
          SUVs. The global pandemic has sparked fear in people even as they go
          about their everyday lives. Many people think to wash their hands
          after coming home from a public space or before eating. Why aren’t we
          talking more about cleaning our cars? <br />
          <br />
          Next time you’re loading those groceries, think of all the germs that
          you encounter in the process. Not only do we often visit public places
          when using our cars, we also spend plenty of time inside them. In
          fact, the AAA Foundation for Traffic Safety reports that “Americans
          drive an average of 11,498 miles each year” as well as “collectively
          spent 70 billion hours behind the wheel” just last year. (AAA
          Foundation for Traffic Safety) Now more than ever, it’s critical that
          we take strides to actively disinfect the spaces we often spend time
          in, especially our vehicles.
        </p>
        <p className="article_subtitle">
          Best Ways to Prevent the Spread of Coronavirus Through Your Car
        </p>
        <p className="article_paragraph">
          The global outbreak of this virus may have you wondering how to best
          protect yourself from COVID-19. You might think of your car as just a
          means of transportation, but have you ever considered it as a vessel
          for germs too?
          <br />
          <br />
          While it seems like common sense to disinfect your car, many drivers
          often forget. CNN Travel published a study done by an Expedia car
          rental company reporting “steering wheels to be four times more germy
          than a public toilet seat, and six times more germ-infested than your
          cell phone” (CNN Travel). Additionally, “cup holders … followed by
          seat belts, the inside door handle, gear shift and audio volume knob”
          were deemed next in line for “most germy”. (CNN Travel) Being aware of
          how vehicles can act as hotspots for germ sharing, what’s the key to
          properly sanitizing your car?
        </p>
        <img
          className="article_img"
          src="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/sanitize+car/sanitize_car2.jpg"
          alt=""
        />

        <p className="article_title">
          Cleaning your vehicle to fight COVID-19:
        </p>
        <p className="article_subtitle">Wiping high-touch areas</p>
        <p className="article_paragraph">
          Disinfectant wipes are easy to use and can help clean hard surfaces in
          the cabin. Most soap and water can also be used to disinfect
          high-touch areas as they also fight against COVID-19. It’s easy to
          overlook spots in the interior. Don’t forget to scrub the door
          handles, windows and center console.
        </p>
        <p className="article_subtitle">Be mindful of your passengers</p>
        <p className="article_paragraph">
          Young children, the elderly and others who are especially endangered
          by COVID-19 should be protected by your sanitation efforts as well.
          Double check that you’re sanitizing the passenger and back seats as
          best as you can.
        </p>
        <p className="article_subtitle">Don’t damage interior surfaces</p>
        <p className="article_paragraph">
          When scrubbing the cabin and interior surfaces, be sure to use the
          proper products to ensure you’re not damaging the vehicle. Solutions
          like bleach and other harsh chemicals can erode the interior and cause
          permanent staining.
        </p>
        <p className="article_subtitle">Protect yourself from existing germs</p>
        <p className="article_paragraph">
          As you’re cleaning the interior of your vehicle, be mindful of the
          germs that may be already lurking as well as the effects of any
          dangerous chemicals that shouldn’t be ingested or directly touched.
          One of the best ways to avoid contact with these dangers is to wear
          protective gloves.
        </p>
        <p className="article_subtitle">Spread the word - not germs</p>
        <p className="article_paragraph">
          Reminding a friend or loved one to properly sanitize their car could
          potentially prevent the spread of COVID-19. Aside from social
          distancing, be sure to follow the proper state and federal guidelines
          on how you can help prevent the spread of the novel coronavirus.
        </p>
      </div>
      <Sources links={links} />
    </div>
  );
};

export default SanitizeCar;
