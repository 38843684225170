import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import VideoPlaceholder from './../img/desktop/video_placeholder.jpg';
import playheadOverlay from '../images/social_responsibility/playhead_overlay.png';
import './AboutUs.scss';

const AboutUs = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setIsClicked(!isClicked);
  };
  return (
    <div className="container__about__us">
      
      <div className="section__our__story">
        <div className="story__title">Our story</div>
        <p>
          Learn more about the DGAC's goals, how it works, and how to get
          involved
        </p>
      </div>
      <div
        className="section__video__story"
        onClick={(event) => handleClick(event)}
      >
        <div
          className={`${
            isClicked ? 'overlay' : ''
          } container__video section__desktop`}
        >
          <ReactPlayer
            light={VideoPlaceholder}
            url="https://www.youtube.com/watch?v=F-K7pk-1TLc"
            width="100%"
            height={isClicked ? '700px' : '320px'}
            playing
            playIcon={
              <div className="play__icon">
                <img src={playheadOverlay} alt="" />
              </div>
            }
          />
        </div>
        <div className="section__mobile">
          <ReactPlayer
            light={VideoPlaceholder}
            url="https://www.youtube.com/watch?v=F-K7pk-1TLc"
            width="100%"
            height="220px"
            playing
            playIcon={
              <div className="play__icon">
                <img src={playheadOverlay} alt="" />
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
