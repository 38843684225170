import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Sponsors.scss';

const AWS_API = `${process.env.REACT_APP_AWS_API}/sponsors?limit=5`;

const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(AWS_API);
        await response.json().then((res) => setSponsors(res));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container__orgs">
      <div className="section__join__us">
        <h1>
          <a
            href="https://constellationagency.typeform.com/to/iymtXs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Us
          </a>
        </h1>
        <div className="separator__line"></div>
        <p>
          This is a time of crisis, with so many vulnerable groups being
          affected. Those of us who are able to contribute time and resources by
          volunteering or donating.
        </p>
      </div>
      <div className="section__sponsors">
        <h1>
          <Link to="/sponsors">Our Sponsors</Link>
        </h1>
        <div className="separator__line"></div>
        <div className="container__sponsors">
          {sponsors.map(({ homepage_logo, name }, idx) => (
            <div className="sponsor__box" key={idx}>
              <div className="sponsor__logo">
                <Link to="/sponsors">
                  <img src={homepage_logo} alt="" />
                </Link>
              </div>
              <div className="sponsor__name">{name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sponsors;
