import React from 'react';

const ThankyouPage = () => {
  return (
    <div>
      <h1 style={{ padding: '2% 7%' }}>Thank you for donating!</h1>
    </div>
  );
};

export default ThankyouPage;
