import React from 'react';
import ReactPlayer from 'react-player';

/* styles */
import './SocialRespTile.scss';

import playheadOverlay from '../images/social_responsibility/playhead_overlay.png';

const SocialResponsibilityTile = ({
  title,
  text,
  image,
  video,
  urlToArticle
}) => {
  return (
    <div className="tile__social">
      {video && video[0].length > 1 ? (
        <ReactPlayer
          url={video[0]}
          light={image[0]}
          width="100%"
          playing
          playIcon={
            <div className="play__icon">
              <img src={playheadOverlay} alt="" />
            </div>
          }
        />
      ) : (
        <img src={image[0]} alt="" />
      )}

      <div className="container__tile__info">
        <h1>{title}</h1>
        <div className="line_separator"></div>
        <div className="tile__text">{text}</div>
        <a href={urlToArticle}>
          <button>READ MORE</button>
        </a>
      </div>
    </div>
  );
};

export default SocialResponsibilityTile;
