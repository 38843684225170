import React, { useEffect } from 'react';

import Meta from './../Meta';
import Sources from '../Sources';

import './Article.scss';

const links = [
  'https://www.magnifymoney.com/blog/auto-loan/buy-cars-online1073730966/',
  'https://www.autonews.com/best-practices/fully-online-process-proves-its-value',
  'https://www.forbes.com/sites/jacknerad2/2020/03/27/buying-a-car-automakers-want-you-to-know-its-not-as-hard-as-you-think/#9c1756636bdd',
  'https://www.dealer.com/insights/car-buying-goes-virtual-online-showroom-2/',
  'https://www.dealer.com/insights/digital-retailing-key-trends-to-watch-and-actions-dealers-can-take/'
];

const VirtualCarBuying = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="article_wrapper">
      <Meta
        title="Tips > Virtual Car Buying"
        description="How to buy a car step by step without needing to set foot in a dealership."
        image=""
      />
      <div className="article_heroimg_virtualcarbuying"></div>
      <div className="article_content">
        <p className="article_main_title">
          Virtual car buying - how to buy a car step by step without needing to
          set foot in dealership
        </p>
        <div className="line__separator"></div>
        <p className="article_subtitle">
          How to buy a car step-by-step without needing to set foot in a
          dealership
        </p>
        <p className="article_paragraph">
          Buying a car can sound daunting, even on a regular day. While we’re
          all feeling the impact of the coronavirus in our everyday lives, car
          shopping shouldn’t have to be a thing of the past. More than ever,
          people need cars to drive to the grocery store, pharmacy and other
          essential spaces.
        </p>
        <p className="article_subtitle">What is virtual car buying?</p>
        <p className="article_paragraph">
          Purchasing a car online through a dealership is the safest way to buy
          a vehicle during the COVID-19 outbreak. Due to federal and state
          mandates, many dealerships’ sales teams are no longer open for
          business in person. These mandates are helping us think of new
          practical ways to safely purchase a vehicle through the internet.
          <br /> <br />
          Many people looking to buy a car at this time are finding ways to
          lower their risk of infection. Here’s a general step-by-step guide to
          purchasing a vehicle online, from start to finish:
        </p>
        <img
          className="article_img"
          src="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/virtual_buying/virtual_buying2.jpg"
          alt=""
        />
        <p className="article_subtitle">
          Step 1: Choose Your Next Vehicle Online
        </p>
        <p className="article_paragraph">
          No matter what your budget is, you should be able to find the perfect
          vehicle for you online. By searching through a dealer’s inventory, you
          can find your next car at home. Browse online at your own pace on any
          device. Most sites are built with a responsive design, allowing each
          vehicle to adapt to varying screen sizes. Finally, once you’ve
          selected your vehicle, depending on your providers, you can even
          schedule an at-home test drive. An associate will come to your home
        </p>
        <p className="article_subtitle">Step 2: Pricing</p>
        <p className="article_paragraph">
          After selecting your next vehicle, determine your price and verify if
          you’re able to get approved for financing online. Depending on the
          dealership, you may be given their lowest offer. In other situations,
          you may receive a quote that is up for negotiation.
        </p>
        <p className="article_subtitle">
          Step 3: Add Service & Protection Plans
        </p>
        <p className="article_paragraph">
          Easily add service and protection plans so you can drive with even
          more confidence.
        </p>
        <p className="article_subtitle">Step 4: Accessorize</p>
        <p className="article_paragraph">
          Customizing your new car has never been easier. Add the features you
          love right from your mobile device.
        </p>
        <p className="article_subtitle">Step 5: Trade-In Deals</p>
        <p className="article_paragraph">
          Depending on if you already have a car you’re looking to trade, you
          may be eligible for a trade-in price. At any point, you’re encouraged
          to ask your sales professional for more information about this option.
        </p>
        <p className="article_subtitle">Step 6: Virtual Paperwork</p>
        <p className="article_paragraph">
          While it may seem counterintuitive at first, the paperwork process can
          become much more simple when handling it online. Be prepared to submit
          items like a credit application, a driver’s license, and a valid
          insurance card. In some cases, cash buyers may need to fill out
          additional forms to settle factors like credit and purchase
          eligibility. Final paperwork is then usually sent to you with your
          vehicle.
        </p>
        <p className="article_subtitle">Step 7: Your Approval</p>
        <p className="article_paragraph">
          Once your final online paperwork has been approved and you’ve
          purchased your next vehicle, the delivery process begins. A sales
          professional will be able to provide an electronic deal sheet for
          review. Once approved, your car will be on its way! Some dealerships
          can deliver cars up to 100 miles away.
        </p>
        <p className="article_subtitle">Step 8: Enjoy</p>
        <p className="article_paragraph">
          Congratulations! You’ve successfully purchased your next vehicle. A
          sales professional from your dealership will be able to coordinate the
          sanitation and delivery of your new car.
        </p>
        <p className="article_title">Now’s Your Chance!</p>
        <p className="article_paragraph">
          When looking to purchase your next vehicle, keep in mind that every
          dealership’s online process is different. While some dealerships offer
          other online incentives, others may not. For the best deals, be sure
          to do the proper research before purchasing a car online. Sales
          professionals will almost always be there to assist you as well.
          <br /> <br /> Purchasing your next vehicle online is the best way to
          prevent the spread of the coronavirus while shopping for a car. Online
          shopping allows you to protect yourself and your loved ones from
          potential infection. Dealerships around the country are working hard
          to prioritize the health and happiness of their communities, starting
          with you.
        </p>
      </div>
      <Sources links={links} />
    </div>
  );
};

export default VirtualCarBuying;
