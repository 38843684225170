import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Meta from './Meta';

/* Components */
import Hero from './Hero';
import DashboardService from './DashboardService';
import AboutUs from './AboutUs';
import Sponsors from './Sponsors';
import LocalDealership from './LocalDealership';

import SocialResponsibility from './SocialResponsibility';
import Dealers from './Dealers';
import Features from './Features';
import Press from './Press';

import Volunteers from './Volunteers';
import Stats from './Stats';

const MainPage = (state) => {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [pathname, hash]);

  useEffect(() => {
    if (state.location.state && state.location.state.shouldClick) {
      document.getElementById('donation_button').click();
      state.history.replace('/', null);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="main_container">
      <Meta
        title="The DGAC was created to facilitate and highlight the innovative and inspiring ways that auto dealers are banding together to make a difference during the era of COVID-19. During this time, we must unite instead of compete to drive meaningful change."
        description="The DGAC was created to facilitate and highlight the innovative and inspiring ways that auto dealers are banding together to make a difference during the era of COVID-19. During this time, we must unite instead of compete to drive meaningful change."
        image="https://static.dogoodautocoalition.com/Website/DGAC/share/dgac_share.jpg"
      />
      <div>
        <Hero />
      </div>
      <div id="DashboardService">
        <DashboardService />
      </div>
      <div id="Stats">
        <Stats />
      </div>
      <div id="WeNeedYourHelp">
        <Sponsors />
      </div>
      <div id="RequestAssistance">
        <LocalDealership />
      </div>
      <div id="OurStory">
        <AboutUs />
      </div>
      <div id="SocialResponsibility">
        <SocialResponsibility />
      </div>
      <div id="Features">
        <Features />
      </div>
      <div id="Press">
        <Press />
      </div>
      {/* <div id="tips__section">
        <Tips />
      </div> */}

      {/* <div id="Support">
        <LoanSupport />
      </div> */}
      {/* <div id="News">
        <News />
      </div> */}
      <div id="ParticipatingDealers">
        <Dealers />
      </div>
      <div id="CommunityVolunteers">
        <Volunteers />
      </div>
    </div>
  );
};

export default MainPage;
