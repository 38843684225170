import React from 'react';
import { Link } from 'react-router-dom';
import Tile from './PressTile';

import './Press.scss';

const AWS_API = process.env.REACT_APP_AWS_API;
const Press = () => {
  const [pressInfo, setPressInfo] = React.useState([]);

  // componentDidMount
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API}/press?limit=16`);
        await response.json().then((res) => setPressInfo(res));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="container__press__section">
      <h1>The DGAC in the Press</h1>
      <div className="press__line__separator"></div>
      <div className="press__tiles__section">
        {pressInfo.map(({ id, image_url, link, text, publisher }) => (
          <Tile
            key={id}
            image_url={image_url}
            link={link}
            text={text}
            publisher={publisher}
          />
        ))}
      </div>
      <div className="press__footer__section">
        <div className="press__line__separator"></div>
        <Link to="/press">
          <button>VIEW ALL STORIES</button>{' '}
        </Link>
      </div>
    </div>
  );
};

export default Press;
