import React from "react";

import "./Sources.scss";

const Sources = ({ links }) => {
  return (
    <div className="articleSources_wrapper">
      <div className="article_links">
      <p className="article_sources_title">Links</p>
      <ul>
        {links.map((link, idx) => (
          <a href={link} key={idx}>
            {" "}
            <li>{link}</li>{" "}
          </a>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default Sources;
