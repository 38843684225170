import React from 'react';
import { Redirect } from 'react-router-dom';

const DonatePage = () => {
  return (
    <div>
      <Redirect
        to={{
          pathname: '/',
          state: { shouldClick: true }
        }}
      />
    </div>
  );
};

export default DonatePage;
