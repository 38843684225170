import React, { useEffect } from 'react';

import Meta from './Meta';

import acuraLogo from '../images/loan_support/Acura_logo.png';
import fordLogo from '../images/loan_support/Ford_logo.png';
import genesisLogo from '../images/loan_support/genesis_logo.png';
import volkswagenLogo from '../images/loan_support/Volkswagen_logo.png';
import audiLogo from '../images/loan_support/audi_logo.png';
import chevLogo from '../images/loan_support/chevy_logo.png';
import bmwLogo from '../images/loan_support/bmw_logo.png';
import GMLogo from '../images/loan_support/general_motors_logo.png';
import teslaLogo from '../images/loan_support/tesla_logo.png';
import lexusLogo from '../images/loan_support/lexus_logo.png';
import kiaLogo from '../images/loan_support/kia_logo.png';
import mazdaLogo from '../images/loan_support/mazda_logo.png';
import subaruLogo from '../images/loan_support/subaru_logo.png';
import toyotaLogo from '../images/loan_support/toyota_logo.png';
import jeepLogo from '../images/loan_support/jeep_logo.png';
import porcheLogo from '../images/loan_support/porsche_logo.png';
import miniLogo from '../images/loan_support/mini_logo.png';
import nissanLogo from '../images/loan_support/nissan_logo.png';
import volvoLogo from '../images/loan_support/volvo_logo.png';
import mercedezLogo from '../images/loan_support/mercedez_logo.png';
import infinityLogo from '../images/loan_support/infiniti_logo.png';
import hyundaiLogo from '../images/loan_support/hyunday_logo.png';
import landroverLogo from '../images/loan_support/landrover_logo.png';
import lincolnLogo from '../images/loan_support/lincoln_logo.png';
import maseratiLogo from '../images/loan_support/maserati.png';
import mitsubishiLogo from '../images/loan_support/mitsubishi_logo.png';
import dodgeLogo from '../images/loan_support/dodge_logo.png';
import hondaLogo from '../images/loan_support/honda_logo.png';
import cadillacLogo from '../images/loan_support/cadillac_logo.png';
import ramLogo from '../images/loan_support/ram_logo.png';
import buickLogo from '../images/loan_support/buick_logo.png';
import alfaLogo from '../images/loan_support/alfa_romero_logo.png';
import fiatLogo from '../images/loan_support/fiat_logo.png';
import jaguarLogo from '../images/loan_support/jaguar_logo.png';
import chryslerLogo from '../images/loan_support/chrysler_logo.png';

import './AllLoanSupport.scss';

const logos = [
  {
    img: acuraLogo,
    name: 'Acura',
    link: 'https://www.acura.com/',
    text: 'Defer monthly payments for 90 days on select models.'
  },
  {
    img: fordLogo,
    name: 'Ford',
    link: 'https://www.ford.com/',
    text:
      '0% APR Financing for 60 months on select models. 3 months deferred, 3 months paid, up to 6 months relief.'
  },
  {
    img: genesisLogo,
    name: 'Genesis',
    link: 'https://www.genesis.com/us/en/genesis-cares.html',
    text:
      'If you purchase a new Genesis and experience a job loss Genesis will make your payment for up to 6 months. Also 0% APR for 84 months and defer payments for up to 120 days on the 2019 Genesis G70.'
  },
  {
    img: volkswagenLogo,
    name: 'Volkswagen',
    link: 'https://www.vw.com/special-offers/',
    text:
      "0% APR Financing for up to 72 months plus defer first month's payment for 180 days on select models."
  },
  {
    img: audiLogo,
    name: 'Audi',
    link: 'https://www.audiusa.com/special-offers',
    text: "Defer first month's payment for 60 days on select models."
  },
  {
    img: chevLogo,
    name: 'Chevrolet',
    link:
      'https://www.chevrolet.com/current-deals#?filterby=all&requestedPostalcode=10001&postalcode=10001&open-panel=trax:trax',
    text:
      '0% APR Financing for 84 months plus defer monthly payments for 120 days on select models.'
  },
  {
    img: bmwLogo,
    name: 'BMW',
    link:
      'https://www.bmwusa.com/90-day-finance-offer.html?modal=legal-disclaimer',
    text:
      '90 days to first payment and complementary maintenance for your new BMW.'
  },
  {
    img: GMLogo,
    name: 'General Motors',
    link: 'https://www.gmc.com/here-to-help',
    text:
      '0% APR Financing for 84 months plus defer monthly payments for 120 days on select models.'
  },
  {
    img: teslaLogo,
    name: 'Tesla',
    link: 'https://www.tesla.com/support/incentives',
    text: 'No COVID-19 specific offers'
  },
  {
    img: lexusLogo,
    name: 'Lexus',
    link: 'https://www.lexus.com/peoplefirst/',
    text:
      "0% APR Financing for up to 60 months plus defer first month's payment for 90 days on select models."
  },
  {
    img: kiaLogo,
    name: 'KIA',
    link: 'https://www.kia.com/us/en/accelerate-the-good',
    text:
      "0% APR Financing for up to 75 months plus defer first month's payment for 120 days on select models."
  },
  {
    img: mazdaLogo,
    name: 'MAZDA',
    link:
      'https://www.mazdausa.com/shopping-tools/special-offers-and-incentives',
    text:
      "0.9% APR Financing for 60 months plus defer first month's payment for 90 days on select models."
  },
  {
    img: subaruLogo,
    name: 'Subaru',
    link: 'https://www.subaru.com/special-offers/index.html',
    text: '0% APR Financing for 63 Months on all new 2020 models'
  },
  {
    img: toyotaLogo,
    name: 'Toyota',
    link: 'https://www.toyota.com/local-specials/',
    text: "Defer first month's payment for 90 days on select models."
  },
  {
    img: jeepLogo,
    name: 'Jeep',
    link: 'https://www.jeep.com/',
    text:
      '0% APR Financing for 84 months plus defer monthly payments for 90 days on select models.'
  },
  {
    img: porcheLogo,
    name: 'Porsche',
    link: 'https://www.porsche.com/usa/',
    text: 'No COVID-19 specific offers'
  },
  {
    img: miniLogo,
    name: 'MINI',
    link:
      'https://www.miniusa.com/tools/shopping/offers.html#!/offers/11418/New/hardtop,hardtop4dr,countryman,clubman,convertible,cooperse/2020///',
    text:
      "Receive a waiver on the first 3 payments, up to $1,000, plus defer first month's payment for 90 days when using a retail contract"
  },
  {
    img: nissanLogo,
    name: 'Nissan',
    link:
      'https://www.nissanusa.com/shopping-tools/deals-incentives-offers.html',
    text:
      'Defer monthly payments for 90 days plus Nissan will cover your payment for an additional 60 days on select models.'
  },
  {
    img: volvoLogo,
    name: 'Volvo',
    link: 'https://www.volvocars.com/us',
    text: 'No COVID-19 specific offers'
  },
  {
    img: mercedezLogo,
    name: 'Mercedes-Benz',
    link: ' https://www.mbusa.com/en/special-offers ',
    text:
      "0% APR Financing for 36 months plus defer first month's payment for 90 days on select models."
  },
  {
    img: infinityLogo,
    name: 'INFINITI',
    link: 'https://www.infinitiusa.com/',
    text: 'No COVID-19 specific offers'
  },
  {
    img: hyundaiLogo,
    name: 'Hyundai',
    link: 'https://www.hyundaiusa.com/us/en/special-programs/hyundai-assurance',
    text:
      '0% APR Financing for 84 months plus defer monthly payments for 120 days on select models.'
  },
  {
    img: landroverLogo,
    name: 'Land Rover',
    link: 'https://www.landroverusa.com/current-offers/index.html',
    text:
      "0% APR Financing for up to 72 months plus defer first month's payment for 90 days on select models."
  },
  {
    img: lincolnLogo,
    name: 'Lincoln',
    link: 'https://www.lincoln.com/here-for-you/?intcmp=hp-bb-covid',
    text:
      "0% APR Financing for up to 72 months plus defer first month's payments for up to 120 days on select models."
  },
  {
    img: maseratiLogo,
    name: 'Maserati',
    link: 'https://www.maseratiusa.com/us/en/shopping-tools/current-offers',
    text:
      'No-charge lease contract extension up to 6 months, 90-day payment due date extension for eligible leases & purchase customers, fee waivers, and 90 day payment deferral. '
  },
  {
    img: mitsubishiLogo,
    name: 'Mitsubishi',
    link: 'https://www.mitsubishicars.com/new-car-lease-special-offers',
    text: '0% APR Financing for 60 months on select models.'
  },
  {
    img: dodgeLogo,
    name: 'Dodge',
    link: 'https://www.dodge.com/lineup.incentives.html?app=incentives',
    text: 'Defer monthly payments for 90 days on select models.'
  },
  {
    img: hondaLogo,
    name: 'Honda',
    link: 'https://automobiles.honda.com/covid-19',
    text: "Defer first month's payment for 90 days on select models."
  },
  {
    img: cadillacLogo,
    name: 'Cadillac',
    link:
      'https://www.cadillac.com/current-offers#?filterby=all&requestedPostalcode=10001&postalcode=10001',
    text: '0% APR Financing'
  },
  {
    img: ramLogo,
    name: 'RAM',
    link: 'https://www.ramtrucks.com/',
    text:
      '0% APR Financing for 84 months plus defer monthly payments for 90 days on select models.'
  },
  {
    img: buickLogo,
    name: 'Buick',
    link: 'https://www.buick.com/here-to-help',
    text:
      '0% APR Financing for 84 months plus defer monthly payments for 120 days on select models.'
  },
  {
    img: alfaLogo,
    name: 'Alfa Romeo',
    link: 'https://www.alfaromeousa.com/',
    text: 'Defer monthly payments for 90 days on select models.'
  },
  {
    img: fiatLogo,
    name: 'Fiat',
    link: 'https://www.fiatusa.com/lineup.incentives.html?app=incentives',
    text: 'Defer monthly payments for 90 days on select models.'
  },
  {
    img: jaguarLogo,
    name: 'Jaguar',
    link: 'https://www.jaguarusa.com/current-offers/index.html',
    text:
      "0% APR Financing for up to 72 months plus defer first month's payment for 90 days on select models."
  },
  {
    img: chryslerLogo,
    name: 'Chrysler',
    link: 'https://www.chrysler.com/incentives.html?modelYearCode=CUC202005',
    text:
      '$4,205 below MSRP + $4,263 financial savings + no payments for 90 days on 2020 Chrysler Pacifica Limited Gas models.'
  }
];

const AllLoanSupport = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="container__loan">
      <Meta
        title="OEM Loan Support during Pandemic"
        description="Many automakers are offering special incentives to help consumers who need vehicles during this time but have been affected financially by COVID-19."
        image=""
      />
      <div className="content__loan">
        <p className="content__title">OEM Loan Support during Pandemic</p>
        <div className="line_separator"></div>
        <p className="content__text">
          Click the logos below to see details about each OEM's COVID-19
          programs.
        </p>
      </div>
      <div className="container__logos">
        {logos.map(({ img, name, link, text }, idx) => (
          <div className="box__logo" key={idx}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <div
                className={`logo__image ${name === 'Jaguar' ? 'jaguar' : ''}`}
              >
                <img src={img} alt="" className="loanSupport_logo" />
              </div>
            </a>
            <div className="logo_line"></div>
            <p className="dealer_name"> {name}</p>
            <div className="dealer__text">{text} </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllLoanSupport;
