import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Tile from './SocialRespTile';

/* styles */
import './SocialResponsibility.scss';

const SocialResponsibility = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [homepageArticles, setHomepageArticles] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://blog.dogoodautocoalition.com/?rest_route=/wp/v2/pages&per_page=50`
        );
        await response.json().then((res) => {
          setHomepageArticles(res);
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="social_responsibility_wrapper">
      <p className="social_responsibility_title">
        The Era of Social Responsibility in Auto:
        <br />
        What dealers are doing to help
      </p>
      <div className="line_separator"></div>

      <div className="section__social__tiles">
        {homepageArticles
          .filter(
            (singleArticle) =>
              singleArticle.custom_fields.hasOwnProperty('featured') &&
              singleArticle.custom_fields.title &&
              singleArticle.custom_fields.text &&
              singleArticle.custom_fields.image
          )
          .sort(
            (a, b) => b.custom_fields.featured[0] - a.custom_fields.featured[0]
          )
          .map(({ custom_fields, link }, idx) => (
            <Tile
              key={idx}
              video={custom_fields.video}
              image={custom_fields.image}
              title={custom_fields.title}
              text={custom_fields.text}
              urlToArticle={link}
            />
          ))}
      </div>
      <div className="button_wrapper">
        <Link to="/all-articles">
          <button>VIEW ALL ARTICLES</button>
        </Link>
      </div>
    </div>
  );
};

export default SocialResponsibility;
