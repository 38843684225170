import React from 'react';

const AWS_API = process.env.REACT_APP_AWS_API;
const STRIPE_PK_KEY = process.env.REACT_APP_STRIPE_PK_KEY;
const stripe = window.Stripe(STRIPE_PK_KEY);

const StripeDonateBtn = ({ donationAmount }) => {
  const stripeDonationAmount = donationAmount * 100;

  const handleDonationClick = async (e) => {
    e.preventDefault();
    await fetch(`${AWS_API}/donate`, {
      method: 'POST',
      body: JSON.stringify({
        amount: stripeDonationAmount
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(async ({ id }) => {
        await stripe.redirectToCheckout({
          sessionId: id
        });
      });
  };

  const styles = {
    fontSize: '19px',
    width: '150px',
    borderRadius: '10px',
    borderColor: 'black',
    backgroundColor: '#1f6aca',
    fontWeight: 'bold',
    color: 'white',
    padding: '20px 20px',
    marginRight: '25px',
    marginTop: '10px',
    cursor: 'pointer',
    border: 'none',
    margin: '0 auto'
  };

  return (
    <div>
      <button style={styles} onClick={(e) => handleDonationClick(e)}>
        Donate
      </button>
    </div>
  );
};

export default StripeDonateBtn;
