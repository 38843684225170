import React, { useEffect, useState } from 'react';

/* styles */
import './SponsorsPage.scss';

const AWS_API = `${process.env.REACT_APP_AWS_API}/sponsors`;

const SponsorsPage = () => {
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await fetch(AWS_API);
        await response.json().then((res) => setSponsors(res));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="sponsors_page_wrapper">
      <p className="features_title">Sponsors</p>
      <div className="line_separator"></div>
      <div className="sponsors_container">
        {sponsors.map(({ logo, name, info, link }) => (
          <div className="single_sponsor">
            <div className="logo_content">
              <a href={link}>
                {' '}
                <img src={logo} alt="" />
              </a>
            </div>
            <div className="single_sponsor_content">
              <p className="sponsor_name">{name}</p>
              {info.map((paragraph) => (
                <p className="paragraph">{paragraph}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="help_section">
        <p className="help_title">We need your help</p>
        <a href="mailto:dgac@constellationagency.com">
          <button>BECOME A SPONSOR</button>
        </a>
      </div>
    </div>
  );
};

export default SponsorsPage;
