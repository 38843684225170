import React from 'react';

import './DonationPopup.scss';
import hands_logo from './../img/logo/dgac_hands.png';

import StripeDonateBtn from './StripeDonateBtn';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PopUpDonation = ({ onDonateClick, hideOverlay }) => {
  const [amount, setAmount] = React.useState(null);
  const [customInput, setCustomInput] = React.useState(300);
  const [displayThankyou, setDisplayThankyou] = React.useState(false);
  const [error, setError] = React.useState(false);

  const amountSelectorHandler = () => (event) => {
    setAmount(event.target.value);
  };

  const handleCustomField = (event) => {
    setCustomInput(event.target.value);
  };

  return (
    <div className="donation__popup__container">
      {!displayThankyou ? (
        <div>
          <div className="popup_header">
            <div className="x-wrapper">
              <img src={hands_logo} className="hands_logo" alt="" />
              <FontAwesomeIcon
                icon={faTimesCircle}
                size="1x"
                onClick={() => hideOverlay()}
              />
            </div>
            <p className="popup_title">Do Good Auto Coalition</p>
            <p className="popup_subtitle">Let's make a difference</p>
          </div>
          <div className="form_wrapper">
            <form>
              <div className="form-check">
                <label>
                  <input
                    type="radio"
                    name="react-tips"
                    value="50"
                    onClick={amountSelectorHandler()}
                    className="form-check-input"
                  />
                  $50
                </label>
              </div>

              <div className="form-check">
                <label>
                  <input
                    type="radio"
                    name="react-tips"
                    value="100"
                    onClick={amountSelectorHandler()}
                    className="form-check-input"
                  />
                  $100
                </label>
              </div>

              <div className="form-check">
                <label>
                  <input
                    type="radio"
                    name="react-tips"
                    value="250"
                    onClick={amountSelectorHandler()}
                    className="form-check-input"
                  />
                  $250
                </label>
              </div>
              <div className="form-check">
                <label>
                  <input
                    type="radio"
                    name="react-tips"
                    value="other"
                    onClick={amountSelectorHandler()}
                    className="form-check-input"
                  />
                  Other
                </label>
              </div>

              {amount === 'other' && (
                <div className="input_wrapper">
                  <label>
                    ${' '}
                    <input
                      type="number"
                      name="amount"
                      className="amount_input"
                      value={customInput}
                      onChange={(event) => handleCustomField(event)}
                    />
                  </label>
                </div>
              )}
            </form>

            <div className="donate_button">
              {amount && amount !== 'other' ? (
                <StripeDonateBtn
                  donationAmount={parseInt(amount)}
                  setDisplayThankyou={setDisplayThankyou}
                  setError={setError}
                />
              ) : customInput && customInput > 0 && amount === 'other' ? (
                <StripeDonateBtn
                  donationAmount={customInput}
                  setDisplayThankyou={setDisplayThankyou}
                  setError={setError}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="popup_header">
            <div className="x-wrapper">
              <img src={hands_logo} className="hands_logo" alt="" />
              <FontAwesomeIcon
                icon={faTimesCircle}
                size="1x"
                onClick={(event) => {
                  event.preventDefault();
                  setDisplayThankyou(false);
                  onDonateClick(event);
                }}
              />
            </div>
          </div>
          <div className="thankyou__popup__container">
            {!error && (
              <div>
                <h1>Thank you!</h1>
                <p>Donation was successful</p>
              </div>
            )}
            {error && (
              <div>
                <h1>Oops!</h1>
                <p>
                  There was an issue processing your donation, please try again.
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PopUpDonation;
