import React, { useEffect } from 'react';

import './DashboardServicePage.scss';

import Dashboard_header from '../images/dashboard_services/DGAC_Dashboard_Page_Header.jpg';
import Dashboard_features from '../images/dashboard_services/dashboard_features.jpg';
import logo from '../images/dashboard_services/logo-black-lives-matter.png';
import Dashboard_header_mobile from '../images/dashboard_services/DGAC_Dashboard_Page_Header_mobile.jpg';
import Dashboard_feature_mobile from '../images/dashboard_services/DGAC_Dashboardfeatures_mobile.png';

const DashboardServicePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="dashboard_service_page_wrapper">
      <div className="hero_wrapper">
        <img className="desktop" src={Dashboard_header} alt="" />
        <img className="mobile" src={Dashboard_header_mobile} alt="" />
        <div className="hero_content">
          <p>
            Do Good Dashboards <br />
            and Do Good Digital
          </p>
        </div>
      </div>
      <div className="content">
        <p>
          The Do Good Auto Coalition has technology expertise that can be
          leveraged in many different ways to support the work our partners and
          us do.
        </p>
        <div className="content_img_wrapper">
          <img
            src={Dashboard_features}
            alt=""
            className="content_img_desktop"
          />
          <img
            src={Dashboard_feature_mobile}
            alt=""
            className="content_img_mobile"
          />
          <img src="" alt="" className="content_img_mobile" />
        </div>
        <p>
          The Do Good Auto Coalition has developed a real-time customizable
          dashboard for municipalities that layers the meals donated by
          nonprofits with affordable housing and unemployment claims. In doing
          this for the City of Newark, we were able to identify duplication of
          efforts in certain areas where some areas were over served and other
          areas underserved. We were able to also highlight which areas were in
          the most need based on unemployment and other city data. Additionally,
          our data team was able to save hundreds of volunteer hours by
          intelligently grouping the delivery addresses in the most efficient
          way.
          <br /> <br />
          We are hoping to help other groups utilize this tech to uncover
          similar insights to make better use of their resources and time. We
          can also ingest additional data types to help account for those not
          tracked in the current data such as undocumented immigrants,
          subsidized school meals, or other factors.
        </p>
      </div>
      <div className="movement_section">
        <div className="movement_content">
          <div className="movement_image_content">
            <img src={logo} alt="Black live matter logo" className="" />
          </div>
          <div className="movement_text">
            <p>
              We can also track other data related to police resourcing, arrests
              or other data points relevant to the Black Lives Matter movement.
              By bringing transparency to these issues, we can help to drive
              awareness, change and reform.
            </p>
          </div>
        </div>
      </div>
      <div className="content_second">
        <p>
          Additionally, we offer website and technology development support for
          organizations who want to streamline their efforts and optimize their
          marketing or operations to save costs and be able to deploy their
          resources most effectively.
        </p>
      </div>
    </div>
  );
};

export default DashboardServicePage;
