import React, { useEffect } from 'react';

import Meta from './../Meta';
import Sources from '../Sources';

import './Article.scss';

const links = [
  'http://convicts.nyc/news/ny-tough/',
  'https://www.newyorker.com/culture/photo-booth/what-were-buying-for-the-quarantine'
];

const NYTough = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="article_wrapper">
      <Meta
        title="Features > NY Tough"
        description="Banding together nationwide to fight against COVID-19, starting right here in our home of New York City."
        image="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/nytough/features_NYstrong_collage.jpg"
      />
      <div className="article_heroimg_nytough"></div>
      <div className="article_content">
        <p className="article_main_title">NY Tough</p>
        <div className="line__separator"></div>
        {/* <iframe className="article_video" title="ny_tough_video"
          src="https://player.vimeo.com/video/403493566"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
              ></iframe> */}
        <video className="article_video" controls>
          <source
            type="video/mp4"
            src="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/nytough/new_york_tough.mp4"
          />
        </video>
        <p className="article_paragraph">
          The DGAC team is inspired by this video, and we are inspired to fight
          this together. Now is the time for us all to work together to come up
          with innovative and collaborative ways to save lives and support our
          communities.
          <br /> <br />
          “As New Yorkers, we know we’re tough. But frankly, this spirit isn’t
          just for our city right now: it’s for the whole world.“
          <br /> <br />
          Love wins - always.{' '}
        </p>
        <br /> <br />
        <img
          className="article_img"
          src="https://static.dogoodautocoalition.com/Website/DGAC/article+pages/nytough/features_NYstrong_collage.jpg"
          alt=""
        />
        <br /> <br />
      </div>
      <Sources links={links} />
    </div>
  );
};

export default NYTough;
