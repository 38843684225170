import React, { useEffect } from 'react';
import Tile from './SocialRespTile';
import PressTile from './PressTile';

/* IMAGES */
import heroImgDeskop from './../images/dealer_hightlight/beyer/AlKhori_dealerhighlight_desktop_hero.jpg';
import heroImgMobile from './../images/dealer_hightlight/beyer/AlKhori_dealerhighlight_mobile_hero.jpg';

import './DealerHighlightBeyer.scss';

const WORDPRESS_API = process.env.REACT_APP_WORDPRESS_API;
const AWS_API = process.env.REACT_APP_AWS_API;

const DealerHighlightDCHAudi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const [selectedArticles, setSelectedArticles] = React.useState([]);
  const [selectedPress, setSelectedPress] = React.useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${WORDPRESS_API}`);
        await response.json().then((res) => {
          setSelectedArticles(res);
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API}/press?dealer=dch`);
        await response.json().then((res) => {
          setSelectedPress(res);
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);


  return (
    <div className="dealer_highlight_wrapper">
      <img
        className="desktop"
        src={heroImgDeskop}
        alt="Do-Good-Auto-Coalition Intro"
      />
      <img
        className="mobile"
        src={heroImgMobile}
        alt="Do-Good-Auto-Coalition Intro"
      />

      <div className="dealer_content">
        <div className="left_side">
          <div className="line_separator"></div>
          <p className="dealer_highlight">
            DGAC Dealer Highlight:
            <br /> Al Khouri and <br /> DCH Millburn Audi
          </p>
          <a href="https://www.dchmillburnaudi.com/">
            <button className="dealer_button">VISIT WEBSITE</button>
          </a>
        </div>

        <div className="right_side">
          <div className="check_list">
            <p className="list_item">
              Donated 100 N95 masks to local Maplewood NJ first responders
            </p>
            <p className="list_item">
              Used dealership as a drop off location for Maplewood food bank
            </p>
            <p className="list_item">
              Made DCH Millburn Audi shuttle service available for medical
              personnel
            </p>
            <p className="list_item">
              Donated 100 Disposable masks and needed cleaning supplies to St.
              Lucy’s Family Shelter
            </p>
            <p className="list_item">
              Partnered with Ironbound Community Corporation and Table to Table
              to package and deliver 2500 lbs of food to 5 different apartment
              buildings
            </p>
          </div>
        </div>
      </div>
      <div className="container__press__section">
        <h1>DCH Millburn Audi in the Press</h1>
        <div className="press__line__separator"></div>
        <div className="press__tiles__section">
          {selectedPress.map(({ id, image_url, link, text, publisher }) => (
            <PressTile
              key={id}
              image_url={image_url}
              link={link}
              text={text}
              publisher={publisher}
            />
          ))}
        </div>
      </div>
      <div className="social_responsibility_wrapper">
        <p className="dealer_title">Dealer Stories</p>
        <div className="section__social__tiles">
          {selectedArticles
            .filter(
              (singleArticle) =>
                singleArticle.custom_fields.title &&
                singleArticle.custom_fields.title[0].includes('DCH')
            )
            .map(({ custom_fields, link }, idx) => (
              <Tile
                key={idx}
                video={custom_fields.video}
                image={custom_fields.image}
                title={custom_fields.title}
                text={custom_fields.text}
                urlToArticle={link}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default DealerHighlightDCHAudi;
