import React, { useState, useEffect } from 'react';

import './Volunteers.scss';

const AWS_API = process.env.REACT_APP_AWS_API;

const styles = {
  width: '50px'
};

const Volunteers = () => {
  const [leftVolunteers, setLeftVolunteers] = useState([]);
  const [middleVolunteers, setMiddleVolunteers] = useState([]);
  const [rightVolunteers, setRightVolunteers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AWS_API}/volunteers`);
        await response.json().then((res) => {
          let splitter = res.length / 3;
          setLeftVolunteers(res.slice(0, splitter));
          setMiddleVolunteers(res.slice(splitter, splitter * 2));
          setRightVolunteers(res.slice(splitter * 2));
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="section__volunteers">
      <h1>Community Volunteers</h1>
      <div className="title__separator"></div>
      <div className="volunteers">
        <div className="left__volunteers">
          {leftVolunteers.map(({ name }, idx) => (
            <div className="volunteer" key={idx}>
              {name}
            </div>
          ))}
        </div>
        <div className="column_spacer" style={styles}></div>
        <div className="middle__volunteers">
          {middleVolunteers.map(({ name }, idx) => (
            <div className="volunteer" key={idx}>
              {name}
            </div>
          ))}
        </div>
        <div className="column_spacer" style={styles}></div>
        <div className="right__volunteers">
          {rightVolunteers.map(({ name }, idx) => (
            <div className="volunteer" key={idx}>
              {name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Volunteers;
