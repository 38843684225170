import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  faFacebook,
  faTwitter
  //faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.scss';

const Footer = () => {
  let location = useHistory();
  return (
    <div className="footer__wrapper">
      {location.location.pathname === '/' ? (
        <div className="constellation__logo">
          <a href="mailto:dgac@constellationagency.com">
            <button>CONTACT US</button>
          </a>
        </div>
      ) : (
        <div className="footer_buttons_wrapper">
          <div className="constellation__logo">
            <a href="mailto:dgac@constellationagency.com">
              <button>CONTACT US</button>
            </a>
          </div>
          
          <div className="constellation__logo">
            <Link to="/">
              <button className="back_home">&#x3c; BACK TO HOME</button>
            </Link>
          </div>
        </div>
      )}

      <div className="social_icons">
        <div className="single_icon">
          <a
            href="https://www.facebook.com/DoGoodAutoCoalition"
            className="social_icons_links"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </div>
        <div className="single_icon">
          <a
            href="https://twitter.com/DoGoodAutoCo"
            className="social_icons_links"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            <FontAwesomeIcon icon={faTwitter} size="2x" />{' '}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
