import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ title, description, image }) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="Do-Good Auto Coalition" />
        <meta name="description" content={title} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:type" content="website" />
      </Helmet>
    </div>
  );
};

export default Meta;
