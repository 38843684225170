import React from 'react';

import { Link } from 'react-router-dom';

import './DashboardService.scss';
import Dashboard from './../images/dashboard_services/homepage_dashboard.jpg';

const DashboardService = () => {
  return (
    <div className="dashboard_service_wrapper">
      <div className="dashboard_content">
        <p className="dashboard_title">
          Do Good Digital and Do Good Dashboard Services
        </p>
        <p>
          Leveraging our technology expertise to help provide transparency,
          optimization, and awareness of key issues in food scarcity, policing,
          racial inequalities, and more.
        </p>
        <Link to="/dashboard-service">
          <button>LEARN MORE</button>
        </Link>
      </div>
      <div className="dashboard_image_wrapper">
        <img
          src={Dashboard}
          alt="Dashboard graphics"
          className="dashboard_img"
        />
      </div>
    </div>
  );
};

export default DashboardService;
