import React from 'react';

// IMAGES
import heroimgDesktop from './../img/desktop/heroimg_desktop.jpg';
import heroimgMobile from './../img/mobile/heroimg_mobile.jpg';
import DGAC_Logo from './../img/logo/dgaclogo_long.png';
import heart from './../img/svg/heart_icon.svg';

import DonationPopup from './DonationPopup';

import './Hero.scss';

const Intro = () => {
  const [displayPopup, setDisplayPopup] = React.useState(false);

  const onClick = (event) => {
    event.preventDefault();
    setDisplayPopup(!displayPopup);
  };

  const hideOverlay = () => {
    setDisplayPopup(false);
  };

  return (
    <div className="container__intro">
      <div className="intro__info">
        <img
          className="desktop"
          src={heroimgDesktop}
          alt="Do-Good-Auto-Coalition Intro"
        />
        <img
          className="mobile"
          src={heroimgMobile}
          alt="Do-Good-Auto-Coalition Intro"
        />
        <div className="intro__text">
          <div className="logo">
            <img src={DGAC_Logo} alt="DGAC Logo" />
          </div>
          <p>
            We are a 501(c)(3) non-profit designed to help dealerships across
            the country unite in support, lending vehicles to get supplies,
            food, and love to projects and people who need it the most. Join us
            by enrolling in the Coalition today.
          </p>
          <div className="container__slogan">
            <div className="heart__logo">
              <img src={heart} alt="heart" />
            </div>
            <div className="slogan__text">Love Wins... Always.</div>
          </div>
          <div className="container__buttons">
            <div className="color__blue">
              <a
                href="https://constellationagency.typeform.com/to/iymtXs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>JOIN THE COALITION</button>
              </a>
            </div>
            <div className="color__blue">
              <a
                href="https://constellationagency.typeform.com/to/la7OQ5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button>REQUEST ASSISTANCE</button>
              </a>
            </div>
            <div className="color__blue">
              <button id="donation_button" onClick={(event) => onClick(event)}>
                DONATE
              </button>
            </div>
          </div>

          {displayPopup && (
            <div className="overlay">
              <DonationPopup
                onDonateClick={onClick}
                hideOverlay={hideOverlay}
                setDisplayPopup={setDisplayPopup}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Intro;
