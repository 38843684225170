import React from 'react';

import bag from './../img/svg/shopping-bag.svg';
import car from './../img/svg/car-side.svg';
import hospital from './../img/svg/hospital.svg';
import donate from './../img/svg/donate.svg';

import './LocalDealership.scss';

const iconText = [
  {
    icon: bag,
    alt: 'bag icon',
    text:
      'Delivering food or picking up prescriptions for the elderly or at-risk'
  },
  {
    icon: car,
    alt: 'car icon',
    text: 'Providing rides for people who need a safe way to get to work'
  },
  {
    icon: hospital,
    alt: 'hospital icon',
    text: 'Delivering donated supplies to a hospital or medical center'
  },
  {
    icon: donate,
    alt: 'hospital icon',
    text:
      'Using dealer facilities as a collection/ drop off location for donations'
  }
];
const LocalDealership = () => {
  return (
    <div className="container__local_dealer">
      <div className="container__info">
        <h1>Your local dealership is here to help.</h1>
        <div className="line__separator"></div>
        <p>
          Our network of participating dealers have vehicles and personnel ready
          to help their communities during this time of need.
        </p>
      </div>
      <div className="container__icon__text">
        {iconText.map(({ icon, alt, text }, idx) => (
          <div className="icon__text" key={idx}>
            <div className="icon">
              <img src={icon} alt={alt} />
            </div>
            <div className="text">{text}</div>
          </div>
        ))}
      </div>
      <div className="container__footer">
        <div className="line__separator"></div>
        <p>Other ideas or suggestions? Submit them below.</p>
        <a
          href="https://constellationagency.typeform.com/to/la7OQ5"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>REQUEST ASSISTANCE</button>
        </a>
      </div>
    </div>
  );
};

export default LocalDealership;
